import { observer } from "mobx-react-lite"
import { FC, useContext, useEffect, useState } from "react"
import {useLocation, useNavigate} from "react-router-dom"
import {Context} from ".."
import {HomeMenuPage} from "../core/utils/defaults"
import {isNullOrUndefined, prevent} from "../core/utils/utils"

import { FormattedMessage } from "react-intl"

import styles from "../styles/Header.module.scss"
import LangSwitcher from "./LangSwitcher"
import useWindowDimensions from "../core/hooks/useWindowDimensions"

interface HeaderProps {
  isMobile?: boolean
  onOpenMenu: any
  goToSection: any
  animationToggle: boolean
}

const Header: FC<HeaderProps> = ({
  onOpenMenu, goToSection, isMobile = false, animationToggle = false
}) => {

  const { store, loyaltyStore } = useContext(Context)

  const { width } = useWindowDimensions()
  const navigate = useNavigate()
  const location = useLocation()
  
  const [headerToggled, setHeaderToggled] = useState(false);
  const [floatHeaderVisible, setFloatHeaderVisible] = useState(true);
  const [blurHeader, setBlurHeader] = useState(false);

  const [menu1, setMenu1] = useState(false)
  const [menu2, setMenu2] = useState(false)
  const [menu3, setMenu3] = useState(false)
  const [menu4, setMenu4] = useState(false)
  const [menu5, setMenu5] = useState(false)
  const [menu6, setMenu6] = useState(false)
  const [menuOpen, setMenuOpen] = useState(false)
  const [logoAnim, setLogoAnim] = useState(false)
  const [isMenuShown, setIsMenuShown] = useState(false)
  const [isMenuVisible, setIsMenuVisible] = useState(false)

  const goTop = () => {
    if (store.menuSelected != 0) {
      store.setIsHomeBlockVisible(false);
      store.setIsHeaderLocked(false);
      store.setSelectedSlide(0);
      setTimeout(() => {
        store.setMenuSelected(0);
        setTimeout(() => {
          store.setIsHomeBlockVisible(true);
        }, 100);
      }, 200);
    }

    const section = document.querySelector(`#top`);
    if (!isNullOrUndefined(section)) {
      section.scrollIntoView({
        behavior: 'smooth'
      });
    }
  }

  const handleMenu = (event: any) => {
    event.preventDefault();
    event.stopPropagation();

    const url = event.target.pathname || "/";

    if (url == location.pathname) {
      return;
    }
    
    const pb2 = document.getElementById("pb2")
    if (pb2 != null) {
      pb2.style.opacity = "0"
    }

    setTimeout(() => {
      console.log(url);
      store.setIsFaderVisible(true);

      setIsMenuVisible(prev => prev = false);
      setTimeout(() => {
        store.setIsFaderShown(true);
        setTimeout(() => {
          window.scrollTo({ top: 0 })
          setIsMenuShown(prev => prev = false)
          document.getElementsByTagName("html")[0].style.overflow = "unset"
        }, 100)
        setTimeout(() => {
          navigate(url)
        }, 200)
      }, 100)
    }, 100)
  }

  const openMenu = () => {
    setIsMenuShown(prev => prev = true);
    document.getElementsByTagName("html")[0].style.overflow = "hidden"
    setTimeout(() => {
      setIsMenuVisible(prev => prev = true);
      document.getElementsByTagName("html")[0].style.overflow = "hidden"
    }, 100);
  }

  const closeMenu = () => {
    setIsMenuVisible(prev => prev = false);
    document.getElementsByTagName("html")[0].style.overflow = "unset"
    setTimeout(() => {
      setIsMenuShown(prev => prev = false);
      document.getElementsByTagName("html")[0].style.overflow = "unset"
    }, 300);
  }

  useEffect(() => {
    if (animationToggle == true) {
      setTimeout(() => {
        setMenu1(true)
        setTimeout(() => {
          setMenu2(true)
          setTimeout(() => {
            setMenu3(true)
            setTimeout(() => {
              setMenu4(true)
            }, 100)
          }, 100)
        }, 100)
      }, 300)
      setMenu5(true)
      setMenu6(true)
      setMenuOpen(true)
      setLogoAnim(true)
      // setTimeout(() => {
      //   setTimeout(() => {
      //     setTimeout(() => {
      //     }, 100);
      //   }, 100);
      // }, 100);
    }
  }, [animationToggle]);

  useEffect(() => {
    console.log(location.pathname)
    if (location.pathname != "/404") {
      store.setPrevLastRoute(`${store.lastRoute}`)
      localStorage.setItem("prev_last_route", `${store.lastRoute}`)
      setTimeout(() => {
        store.setLastRoute(location.pathname)
        localStorage.setItem("last_route", location.pathname)
      }, 300)
    }

    if (location.pathname == "/") {
      store.setXScr(900)
    } else if (location.pathname == "/franchise") {
      store.setXScr(800)
    } else if (width <= 960) {
      store.setXScr(300)
    } else {
      store.setXScr(0)
    }
  }, [location.pathname])

  useEffect(() => {
    let scrFix = 0;

    const lr = localStorage.getItem("last_route")
    const plr = localStorage.getItem("prev_last_route")
    if (lr != null && plr != null) {
      store.setLastRoute(lr)
      store.setPrevLastRoute(plr)
    }

    const onScroll = (event: any) => {
      //console.log(event.target.scrollingElement.scrollTop);
      const scrollTop = event.target.scrollingElement.scrollTop;
      if (scrollTop >= 20) {
        setHeaderToggled(true);
      } else {
        setHeaderToggled(false);
      }

      console.log(store.xScr)

      if (scrollTop < scrFix && scrollTop > store.xScr) {
        setFloatHeaderVisible(prev => prev = true)
      } else if (scrollTop > store.xScr) {
        setFloatHeaderVisible(prev => prev = false)
      }

      scrFix = scrollTop;
    }
    
    document.addEventListener('scroll', onScroll);

    return () => {
      document.removeEventListener('scroll', onScroll);
    }
  }, []);

  return (
    <>
      <div className={styles.mob_menu + (headerToggled ? " " + styles.fixedd : "")} style={{
        display: isMenuShown ? "block" : "none",
        opacity: isMenuVisible ? "1" : "0"
      }}>
        <div className={styles.mm_wrap}>
          <div className={styles.mm_w_logo}>
            <a href="/"><img src={store.userData != null && store.userData.is_barber == true ? "/assets/images/logo_plus_barber.svg" : "/assets/images/logo_plus_new_new.svg"} /></a>
          </div>
          <div className={styles.mm_w_close} onClick={() => closeMenu()}><i className="fal fa-xmark"></i></div>
            <div className={styles.mm_w_items}>
              <a href="/store" className={(location.pathname == "/franchise" ? " " + styles.active : "")}>Магазин</a>
              {store.isAuth ? <a href="/orders" style={{marginRight: "82px"}} className={(menu3 ? styles.animated : "") + (location.pathname == "/orders" ? " " + styles.active : "")}>Мои заказы</a> : <></>}
              {store.isAuth ? <a href="/account" className={(location.pathname == "/account" ? " " + styles.active : "")}>Мой аккаунт</a> : <></>}
              {store.isAuth ? <a href="/" className={(menu4 ? styles.animated : "")} onClick={(evt) => {
                evt.preventDefault()
                evt.stopPropagation()
              }}>Выйти</a> : <a href="/" className={(menu4 ? styles.animated : "")}>На главную</a>}
            </div>
          <div className={styles.mm_w_lang}><LangSwitcher /></div>
        </div>
      </div>

      <header className={styles.header + " " + styles.antiblured + (headerToggled ? " " + styles.fixed : "") + (!floatHeaderVisible ? " " + styles.white : "") + (location.pathname == "/branches" ? " " + styles.psd : "")}>
        <div className="container">
          {store.isMobile ? <div className="row">
            <div className="col-6">
              <div className={styles.logo + (store.subscribeData != null ? " " + styles.plus : "") + (logoAnim ? " " + styles.animated : "") + (store.userData.is_barber ? " " + styles.brb : "")}>
                <a href="/"><img src={store.userData.is_barber == true ? "/assets/images/logo_plus_barber.svg" : "/assets/images/logo_plus_new_new.svg"} /></a>
              </div>
            </div>
            {store.isAuth && location.pathname.includes("/store") ? <div className="col-6">
              <div className={styles.mobile_menu} style={{opacity: menuOpen ? "1" : "0"}}>
                <button onClick={() => loyaltyStore.setIsRulesVisible(true)} className={styles.hovered_icon}><i className="fas fa-question"></i></button>
                <button onClick={() => store.setIsNotifyVisible(true)} className={styles.hovered_icon + (store.isAuth && (isNullOrUndefined(store.userData.telegram_id) || store.userData.telegram_id == "") ? " " + styles.bell_anim : "")}><i className="fas fa-bell"></i></button>
                <button onClick={() => loyaltyStore.setIsSupportVisible(true)} className={styles.hovered_icon}><i className="fas fa-headset"></i></button>
              </div>
            </div> : <></>}
          </div> : <div className="row">
            <div className="col-2 col-md-2">
              <div className={styles.logo + (logoAnim ? " " + styles.animated : "") + (store.userData.is_barber ? " " + styles.brb : "")} style={{textAlign: "left"}}>
                <a href="/"><img src={store.userData.is_barber == true ? "/assets/images/logo_plus_barber.svg" : "/assets/images/logo_plus_new_new.svg"} /></a>
              </div>
            </div>
            <div className="col-5 col-md-6 offset-md-4">
              <div className={styles.menu + " " + styles.right}>
                <div className={styles.menu_items}>
                  {/* <a href="/loyalty/store" style={{marginRight: "82px"}} className={(menu1 ? styles.animated : "") + (location.pathname == "/loyalty/store" ? " " + styles.active : "")}>Главная</a>
                  {store.isAuth ? <a href="/loyalty/orders" style={{marginRight: "82px"}} className={(menu2 ? styles.animated : "") + (location.pathname == "/loyalty/orders" ? " " + styles.active : "")}>Мои заказы</a> : <></>} */}
                  {/* {store.isAuth ? <a href="/loyalty/account" style={{marginRight: "82px"}} className={(menu3 ? styles.animated : "") + (location.pathname == "/loyalty/account" ? " " + styles.active : "")}>Мой аккаунт</a> : <></>}
                  {store.isAuth && location.pathname == "/loyalty" ? <a href="/" className={menu4 ? styles.animated : ""} onClick={(evt) => {
                    evt.preventDefault()
                    evt.stopPropagation()
                  }}>Выйти</a> : <a href="/" className={menu4 ? styles.animated : ""} onClick={(evt) => {
                    evt.preventDefault()
                    evt.stopPropagation()
                  }}>Выйти</a>} */}
                  {store.isAuth && location.pathname.includes("/store") ? <>
                    <a href="#" className={styles.bell + (menu1 ? " " + styles.animated : "")} onClick={(evt) => {
                      prevent(evt)
                      loyaltyStore.setIsRulesVisible(true)
                    }}><span><i className="fas fa-question"></i></span></a>
                    
                    <a href="#" className={styles.bell + (store.isAuth && (isNullOrUndefined(store.userData.telegram_id) || store.userData.telegram_id == "") ? " " + styles.bell_anim : "") + (menu1 ? " " + styles.animated : "")} onClick={(evt) => {
                      prevent(evt)
                      store.setIsNotifyVisible(true)
                    }}><span><i className="fas fa-bell"></i></span><span><i className="fas fa-bell-on"></i></span></a>
                    
                    <a href="/support" className={styles.bell + (menu1 ? " " + styles.animated : "")} onClick={(ev) => {
                      prevent(ev)
                      loyaltyStore.setIsSupportVisible(true)
                    }}><span><i className="fas fa-headset"></i></span></a>
                  </> : <></>}
                </div>
              </div>
            </div>
          </div>}
        </div>
      </header>
    </>
  )
}

export default observer(Header)